import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

var config = {
  apiKey: "AIzaSyBJ8MURdtzyUMW5e0SfRuxwjaIqMbHaAW4",
  authDomain: "zadnej-klan.firebaseapp.com",
  databaseURL: "https://zadnej-klan.firebaseio.com",
  projectId: "zadnej-klan",
  storageBucket: "zadnej-klan.appspot.com",
  messagingSenderId: "659410676929"
};
firebase.initializeApp(config);

const storage = firebase.storage();
const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);

export const getImageUrls = async () => {
  const result = await db.collection("images").orderBy("base", "desc").get();
  return result.docs.map(document => {
    const data = document.data();
    return {
      originalUrl: data.originalUrl,
      fullUrl: data.fullUrl,
      thumbnailUrl: data.thumbnailUrl,
      base: data.base,
      type: data.type
    };
  });
};

export const mediaType = Object.freeze ({ match: "match", misc: "misc", fun: "fun" });

// eslint-disable-next-line
const importMedia = () => {
  const data = require("./data.json");
  const type = "misc";
  data.forEach(async (fileInfo) => {
    const originalUrl = await storage.ref(`image/${type}/original/${fileInfo.original}`).getDownloadURL();
    const fullUrl = fileInfo.full ? await storage.ref(`image/${type}/full/${fileInfo.full}`).getDownloadURL() : null;
    const thumbnailUrl = await storage.ref(`image/${type}/thumbnail/${fileInfo.thumbnail}`).getDownloadURL();

    console.log(fileInfo);

    db.collection("images").add({
      ...fileInfo,
      originalUrl,
      fullUrl,
      thumbnailUrl,
      type: type
    })
  });
}

// eslint-disable-next-line
const deleteMedia = async () => {
  var jobskill_query = db.collection("images").where("type", "==", "fun");
  jobskill_query.get().then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      console.log(doc);
      doc.ref.delete();
    });
  });
}
