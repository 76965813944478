import React, { Component } from 'react';
import { Image } from './image';

export class Gallery extends Component {
  render() {
    const arrayCount = 4;

    let arrays = [arrayCount];
    for (var i = 0; i < arrayCount; i++) {
      arrays[i] = [];
    }

    this.props.media.forEach((image, index) => arrays[index % arrayCount].push(image));

    return (
      <div className="gallery">
        {this.props.media.map(img => <Image key={img.id} id={img.id} thumbnailUrl={img.thumbnailUrl} onImageClick={this.props.onImageClick} base={img.base} />)}
      </div>
    );
  }
}
