import React, { Component } from "react";

export class About extends Component {
  render() {
    return(
      <>
        <p>Welcome to pages of an InstaGib Unreal Tournament clan - <b>Žádnej Klan</b>.</p>
        <p>It sprang to life in <b>March 2004</b> on a Czech server GameZone.cz and quickly transcended to international iCTF UT99 gaming on <i title="ClanBase CID of ZK was 704759">ClanBase.com</i> where it flourished until the server's inevitable end in December 2013.</p>
        <p>Although the spirit of the clan will remain alive forever, some <b>memories</b> might get lost along the way. This website is dedicated to keep them safe and sound.</p>
        <p>Throughout its history ZK has been very successful in creating many strong <b>friendships</b>, winning plenty of its over 400 official matches, participating in many more fun-wars and especially having fun.</p>
        <p>It has been an honor to be among the mixture of amazing, passionate, helping, crazy, hilarious people. Thank you.</p>
      </>
    );
  }
}
