import React, { Component } from "react";
import { members } from './utils';
import { Member } from "./member";

export class Members extends Component {
  render() {
    return(
      <>
        <h3>List of Žádnej Klan members</h3>
        <ul className="user-list">
          {members.map(member => <Member key={member.simplifiedName} name={member.name} simplifiedName={member.simplifiedName} />)}
        </ul>
      </>
    );
  }
}
