const getMemberSimplifiedName = (name) => {
  return name
    .replace(/Wi3les/, "Wiggles")
    .replace(/\*CZE\*/g, "")
    .replace(/CZ/g, "")
    .replace(/\]-\[/g, "H")
    .replace(/®/g, "r")
    .replace(/\$/g, "s")
    .replace(/[©`_^]/g, "")
    .replace(/[@4]/g, "a")
    .replace(/\/\\\/\\/g, "m")
    .replace(/\/\\/g, "a")
    .replace(/0/g, "o")
    .replace(/[1!]/g, "i")
    .replace(/3/g, "e")
    .toLowerCase()
};

const getMember = (memberName) => ({
  name: memberName,
  simplifiedName: getMemberSimplifiedName(memberName)
});

export const members = [
  "Alpha",
  "AyRia",
  "BDs",
  "BM",
  "buschmenCZ",
  "chafa",
  "corein",
  "doublekill",
  "Drakir",
  "DreadKnight",
  "dyinactive",
  "dywm",
  "eXtreme",
  "GAHIS*CZE*",
  "GamemasteR",
  "gh^",
  "Goldy",
  "]-[/\\®®Y$",
  "1c3m@n",
  "Im/\\/\\ortaL",
  "infectedx",
  "Ingela",
  "Inter_Septor",
  "j3b4t0r",
  "J0e-H0vAd0",
  "Komisek",
  "KoWa",
  "maiki",
  "M@Z!",
  "MORbYdLO",
  "Morpheus",
  "MURILLA",
  "Mysska",
  "n0^",
  "noBUGs^",
  "Opolo_CZ",
  "RaiZen",
  "rappa",
  "rAvje",
  "Reffud",
  "ridgeback",
  "Scope",
  "senTi",
  "skay",
  "SmatZ!",
  "Sonny",
  "Source©",
  "SPRT`",
  "t/\\rO",
  "TL^",
  "Vendy_",
  "Wi3les",
  "Wodan",
  "yahara",
  "Yoda"
].map(getMember);
