import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

export class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  handleImageLoaded = () => this.setState({ loaded: true });

  render() {
    return (
      <div className="image-container">
        <LazyLoad height={150} offset={150} once throttle={300} placeholder={<div className="placeholder"></div>}>
          <img
            src={this.props.thumbnailUrl}
            data-media-id={this.props.id}
            onClick={this.props.onImageClick}
            onLoad={this.handleImageLoaded}
            alt={this.props.base}
            className={this.state.loaded ? 'loaded' : ''}
          />
        </LazyLoad>
      </div>
    );
  }
}