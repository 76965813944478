import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Members } from "./members";
import { About } from "./about";
import { Media } from "./media";

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <div className="content">
            <header>
              <div className="cover"><img src="/img/zk-logo.png" alt="Žádnej Klan" /></div>
              <nav>
                <ul>
                  <li><Link to="/">About</Link></li>
                  <li><Link to="/members">Members</Link></li>
                  <li><Link to="/images">Images</Link></li>
                </ul>
              </nav>
            </header>

            <main>
              <Route path="/" exact component={About} />
              <Route path="/members/" component={Members} />
              <Route path="/images/" component={Media} />
            </main>
          </div>

          <footer>
            <span>This clan would not have been made possible without its members.</span> <span className="zk-forever" data-date={(new Date()).getFullYear()}>&gt;ZK&lt;</span>
          </footer>
        </>
      </Router>
    );
  }
}

export default App;
