import React, { Component } from 'react';
import { Gallery } from './gallery';
import { Lightbox } from './lightbox';
import { getImageUrls, mediaType } from '../shared';

export class Media extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      media: [],
      showLightbox: false,
      currentLightboxId: 0,
    }
  }

  async componentDidMount() {
    const media = await getImageUrls();
    const result = media.filter(m => m.type === mediaType.match)
      .concat(media.filter(m => m.type === mediaType.fun))
      .concat(media.filter(m => m.type === mediaType.misc))
      .map((media, index) => ({ id: index, ...media }))
    this.setState({
      media: result,
      loading: false,
    });
  }

  onPageClick = (event) => {
    this.setState({
      currentPage: parseInt(event.target.getAttribute('data-page-id'))
    });
  }

  onImageClick = (event) => {
    this.setState({
      showLightbox: true,
      currentLightboxId: parseInt(event.target.getAttribute('data-media-id'))
    });
  };

  onPreviousClick = () => this.changeCurrentLightbox(-1);

  onNextClick = () => this.changeCurrentLightbox(1);

  changeCurrentLightbox = (increment) => {
    const length = this.state.media.length;
    this.setState((previousState) => {
      const nextLightboxId = (((previousState.currentLightboxId + increment) % length) + length) % length;
      return {
        currentLightboxId: nextLightboxId,
      };
    })
  }

  onCancelClick = () => {
    this.setState({
      showLightbox: false,
      currentLightboxId: -1
    });
  }

  render() {
    if(this.state.loading) {
      return (null);
    }

    const currentLightbox = this.state.media.find(img => img.id === this.state.currentLightboxId);
    const currentLightboxSrc = (currentLightbox && currentLightbox.originalUrl) || (currentLightbox && currentLightbox.originalUrl) || '';

    return (
      <div className="media">
        <Gallery media={this.state.media} onImageClick={this.onImageClick} />
        <Lightbox
          visible={this.state.showLightbox}
          mediaSrc={currentLightboxSrc}
          onCancelClick={this.onCancelClick}
          onNextClick={this.onNextClick}
          onPreviousClick={this.onPreviousClick}
          currentNumber={this.state.currentLightboxId + 1}
          mediaCount={this.state.media.length}
        />
      </div>
    )
  };
}

