import React, { Component } from 'react';

export class Lightbox extends Component {
  render() {
    if (!this.props.visible)
      return null;

    return (
      <div className="lightbox">
        <span className="close" onClick={this.props.onCancelClick}>&times;</span>
        <div className="lightbox-content">
          <img src={this.props.mediaSrc} alt="" />
          <div className="image-progress">{this.props.currentNumber}/{this.props.mediaCount}</div>
          <div className="prev" onClick={this.props.onPreviousClick}>&#10094;</div>
          <div className="next" onClick={this.props.onNextClick}>&#10095;</div>
        </div>
      </div>
    );
  }
}