import React, { Component } from "react";

export class Member extends Component {
  render() {
    return (
        <li title={this.props.simplifiedName}>{this.props.name}</li>
      );
  }
}

